import  React from 'react';
import { Link } from 'gatsby';
import {Link as GoLink} from 'react-scroll/modules';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import ListBrand from '../../components/list-brand';
import ListLink from '../../components/list-link';
import Image from '../../components/Image';

const BeginnerPage = () => (
      <Layout page="beginner">
        <Seo title="FOR BEGINNER"/>
        <section className="page-head">
          <ListBrand/>
          <ListLink/>
          <h2 className="main-title"><span>FOR</span><br className="sp" /><span>BEGINNER</span></h2>
        </section>
        <section className="page-content flex-layout sp-read">
          <div className="main-content">
            <div className="content-block">
              <h3 className="sub-title"><span>初めての方へ</span></h3>
              <p>薪ストーブをご検討中のお客様へ選ぶポイントや設置までの流れをご紹介します。</p>
              <Image className="w-full mt-12" filename="beginner/beginner-01.jpg" />
            </div>
            <div id="howto" className="content-block">
              <p className="text-2xl c-yellow mb-4">薪ストーブの設置と使用方法</p>
              <p className="mb-4">薪ストーブは置きたい場所に薪ストーブを置くだけで使用できる訳ではありません。壁が高温にならないための離隔距離、薪ストーブの荷重に耐えられるだけの床の補強、床が燃焼しないための保護、子供やペットが触れないようフェンスを設置するなどの配慮・対策、薪ストーブの燃焼に必要な空気の流れを作りだす煙突の設計など専門知識を必要とするいくつもの条件が必要となります。これらを一つでも怠ってしまうと薪ストーブがうまく燃えないだけでなく最悪の場合火事などの重大な災害につながる可能性があります。薪ストーブの設置に関しては必ず専門知識を持った専門店におこなってもらってください。</p>
              <p className="mb-4">煙突を接続しなくてもよい薪ストーブはないかと思われる方もいらっしゃるかもしれませんが薪を燃やしている以上は必ず煙が発生してそれを排出する必要があります。現時点で薪ストーブと呼ばれるものには必ず煙突の設置が必要です。ただし屋内のスペースの確保や景観を損ねたくないなどのご希望がある場合は煙突を本体後方に接続して屋内にある煙突を最小限にする方法などもあります。こういった具体的な煙突の設置プランの相談も専門店でおこなうことができます。</p>
              <p className="mb-4">設置には専門知識が必要ですが一方で操作は実にシンプルです。
                薪ストーブにも素材やデザイン、サイズ、カラーバリエーションなどによって様々な種類がありますが、基本的には「オン」「オフ」スイッチやコンセント類はついておらず、薪をくべ足したり、給気口を開いたり絞ったりすることによって暖房加減を調整します。</p>
              <p className="mb-10">たくさん薪を入れて給気量を多くすれば火力は上がり逆にすれば火力は下がる、最初は加減が難しいかもしれませんがこういったシンプルな作業を繰り返していくうちにその薪ストーブの特徴を理解していきます。最初はこちらの思う通りに動いてもらえないものが長い時間を共にすることで思い通りに動くようになる、そのひと手間も薪ストーブの魅力の一つと言えるかもしれません。</p>
              <Link to="/beginner/knowledge/" className="btn full white">薪ストーブの基礎知識はこちら</Link>
            </div>
            <div id="type" className="content-block">
              <p className="text-2xl c-yellow mb-4">薪ストーブの種類</p>
              <div className="md:flex mb-12">
                <div className="md:w-1/2 md:pr-8">
                  <p className="text-xl c-gray mb-4">鋳物と鋼板</p>
                  <p>薪ストーブに使われる素材を大きく分けると、鋳物と鋼板があります。</p>
                  <p className="mb-6">鋳物と鋼板を比較すると、鋳物は金属を加熱して溶かした後で、型に流し込んでから冷やして固めてつくった金属製品のことで、鋼板の薪ストーブは、その名の通りに鉄の板からつくられた製品のことです。</p>
                  <p className="mb-6">鋳物は熱に強く一度暖まると冷めにくい性質があるので薪ストーブの火が消えてからも数時間暖かい状態が続きます。また、鋳物でつくられた薪ストーブの多くには、本体にキレイなレリーフや模様が施されているのが大きな特徴です。</p>
                  <p className="mb-6">こういったレリーフや模様などはデザインの面だけではなく本体の表面積を広くして熱の放出量を増やすといった効果もあります。これに対して鋼板の薪ストーブは、暖まりやすく冷めやすいといった性質を持ち、自由自在に鉄の板を折り曲げられることから、アール加工がしやすいのも大きな特徴です。</p>
                  <p className="mb-6">日本で使用されている薪ストーブの80～90％が鋳物製の薪ストーブとも言われております。</p>
                </div>
                <div className="md:w-1/2 md:pl-8">
                  <Image className="full-image" filename="beginner/type-01.jpg" />
                </div>
              </div>
              <p className="text-xl c-gray mb-4">鋳物と鋼板</p>
              <div className="md:flex mb-8">
                <div className="md:w-3/12 md:pr-8 mb-6 md:mb-0">
                  <Image className="full-image" filename="beginner/type-02-01.jpg" />
                </div>
                <div className="md:w-9/12 md:pl-8">
                  <p className="text-lg font-bold mb-4">輻射熱方式</p>
                  <p>
                    薪を燃焼させることでストーブの外壁を暖め、ストーブ表面に接する空気を直接暖めるのが輻射熱方式です。そのため、ストーブから放射される暖かさが肌に直接に感じられます。<br />
                    輻射熱方式の薪ストーブはストーブ自体が非常に高温になるため、熱に強い鋳物ストーブが使われるのが一般的です。<br />
                    対流式に比べると家の壁や家具から距離を置く必要があります。
                  </p>
                </div>
              </div>
              <div className="md:flex mb-12">
                <div className="md:w-3/12 md:pr-8 mb-6 md:mb-0">
                  <Image className="full-image" filename="beginner/type-02-02.jpg" />
                </div>
                <div className="md:w-9/12 md:pl-8">
                  <p className="text-lg font-bold mb-4">対流熱方式</p>
                  <p>
                    ストーブ外壁に空気流通層を設け、下部より冷たい空気を吸い込み、上部から暖まった空気を放出するのが対流熱方式です。この場合でも、ストーブ天板面と前面部は輻射熱が放出されます。<br />
                    側壁・後壁面は空気流通層があり、比較的高温になりにくいため安全性が高く、壁や家具により近づけて設置することができます。
                  </p>
                </div>
              </div>
              <div className="md:flex">
                <div className="md:w-1/2 md:pr-8">
                  <p className="text-xl c-gray mb-4">煙突の種類</p>
                  <p>昔はシングル煙突しかありませんでしたが、今では2重断熱煙突が当たり前となっています。<br />
                    シングル煙突と2重断熱煙突を比較すると、安全性やドラフトが安定してストーブの性能を引き出しますので2重断熱煙突を選ぶ方が増えております。</p>
                  <Link to="/chimney/" className="text-link white py-0 text-lg mt-8">煙突について詳しくみる</Link>
                </div>
                <div className="md:w-1/2 md:pl-8">
                  <Image className="full-image" filename="beginner/type-03.jpg" />
                </div>
              </div>
            </div>
            <div id="flow" className="content-block">
              <p className="text-2xl c-yellow mb-4">薪ストーブの価格と設置までの流れ</p>
              <p className="text-xl c-gray mb-4">薪ストーブに必要な費用</p>
              <p>薪ストーブのある生活を検討するにあたって、「何が必要で、それぞれいくら掛かるのか？」というようなご質問をよく頂きます。薪ストーブをご利用頂くには、薪ストーブ本体を購入して頂くだけではなく、様々な段階を経て、それ以外にも煙突や施工に伴う費用等がかかります。<br />
                それぞれ必要となる価格をご紹介致しますので、導入を検討されている方は、是非参考にして下さい。</p>
              <div className="md:flex mt-6 overflow-hidden">
                <div className="md:w-4/12 bg-black text-white md:mr-10">
                  <p className="text-lg text-center p-4">薪ストーブ本体価格<br />20万円～60万円</p>
                  <Image className="full-image" filename="beginner/flow-01.jpg" />
                  <p className="p-4">アンデルセンストーブ価格(特注モデルを除く)は約20万円～60万円程度となります。</p>
                </div>
                <div className="md:w-4/12 bg-black text-white md:mx-5 md:mt-0 mt-6">
                  <p className="text-lg text-center p-4">煙突部材価格<br />50万円～70万円</p>
                  <Image className="full-image" filename="beginner/flow-02.jpg" />
                  <p className="p-4">断熱材入り二重煙突(SCS二重断熱煙突)を一般的な木造２階建ての住宅に設置した場合、約50万円～約70万円程度となります。<br />
                    ストーブ本体の設置階や場所によって使用する煙突の本数や種類により価格が異なります。</p>
                </div>
                <div className="md:w-4/12 bg-black text-white md:ml-10 md:mt-0 mt-6">
                  <p className="text-lg text-center p-4">取付施工費用<br />30万円～40万円</p>
                  <Image className="full-image" filename="beginner/flow-03.jpg" />
                  <p className="p-4">本体と煙突の取付施工の諸費用としては、約30万円～40万円程度となります。<br />
                    設置場所の状況に応じて価格は変動いたしますので、最終的には現地調査後のお見積りとなります。</p>
                </div>
              </div>
              <p className="md:text-right my-6">※あくまでも参考価格(目安)となります。</p>
              <p>平均的な予算としましては、
                <span className="c-yellow text-lg py-2 block">100～170万円程度を目安にしましょう。</span>
                尚、レンガ等で制作した不燃ステージや不燃壁を制作する場合は、別途費用が発生します。</p>
              <p className="mt-6">アンデルセントーブの大きな特徴として、本体を設置した床面の表面温度が低温炭化の発生する温度に達しない様、長い脚が組まれている上、本体ボトム(底)部分にヒートシールド(遮熱板)が装備されています。<br />
                その為、製作日数と費用の掛かるレンガ等の不燃ステージを用意せずに、ドアーを開閉する際に落下する熾火や火の粉からフローリングやカーペットの表面を保護する、スチールやガラス製のフロアプレートを敷き詰めるだけで問題はなく、コストパフォーマンスと作業効率に優れています。<br />
                ＊短脚仕様モデルを除く
              </p>
              <div className="mt-12">
                <p className="text-xl c-gray mb-4">購入・設置までの流れ</p>
                <p>薪ストーブを購入して設置するまでにはいくつかの手順が必要になります。<br />
                  情報の収集からはじまり、プランニング、見積りの入手を経てご購入いただいてから、煙道部材等を手配し、取付施工完了、引き渡しまでに至る大まかな流れと期間についてご説明いたします。</p>
                <div className="flow mt-6">
                  <div className="flow-block">
                    <div className="flow-step"><span>1</span><p>情報の収集</p></div>
                    <div className="flow-content">
                      <p>薪ストーブの専門誌やカタログ等から情報を入手して、薪ストーブのある生活のイメージを具体化してみましょう。<br />
                        目的と用途や価格によって、どの薪ストーブを購入するのが良いかをご検討ください。新築やリフォームの際に薪ストーブの購入を検討されている場合は建物全体の設計を依頼している建築業者へ相談を忘れずに。<br />
                        家屋の正式な図面が出来上がってからになると薪ストーブの使用や熱効率の点で最も適した場所への設置が出来ない場合があるため、プランニングの初期段階で導入の意思を伝えておきましょう。</p>
                    </div>
                  </div>
                  <div className="flow-block">
                    <div className="flow-step"><span>2</span><p>ショールーム等で<br className="pc" />体験</p></div>
                    <div className="flow-content">
                      <p>弊社ショールームまたは正規販売代理店で薪ストーブの暖かさを肌で感じてみましょう。実際の暖かさを体感していただくとともに、間取りやインテリアのイメージをしてみてください。<br />
                        経験と知識の豊富なスタッフがしっかりご説明いたします。建物の図面(平面図・立面図など)をご持参いただくとより具体的なアドバイスが可能です。</p>
                      <Link to="/showroom/" className="text-link white py-0 text-lg mt-8">ショールームについて</Link>
                    </div>
                  </div>
                  <div className="flow-block-wrap">
                    <p className="vertical">プランニング 〜 お見積もりと契約まで約１ヶ月程度</p>
                    <div className="flow-block">
                      <div className="flow-step"><span>3</span><p>プランニング</p></div>
                      <div className="flow-content">
                        <p>建物の設計図面をもとに機種の選定や煙突の配管プラン、メンテナンス等を考慮した最適な設置場所等を検討します。
                          既存の建物への設置を考えている場合は、本体を設置する場所の耐火対策や床の耐荷重対策、煙突を取り付けるためには屋根や壁に穴を開ける必要がある為、設置現場での現調を行い、設置が可能か否かの判断をして貰い、可能な場合は具体的にどのような工事が必要となるかなど丁寧にアドバイスしてくれます。<br />
                          いずれの場合もストーブ本体廻りの不燃処理等も含めて経験と実績が豊富な地域のストーブ施工販売代理店で納得の行くまで足を運びプランを練りましょう。<br />
                          更に、施工販売代理店が実施しているアフターメンテナンスの内容や燃料の薪の供給についても確認が必要です。<br />
                          その他、都市部の住宅地では近隣の建物との距離が近いため、薪ストーブに対しての近所付き合いがうまくいくか不安を抱く人も多いので、住宅地での施工に慣れている施工販売代理店では、今までの経験に基づき、近所付き合いのコツなどを伝授してくれるところもあります。</p>
                      </div>
                    </div>
                    <div className="flow-block">
                      <div className="flow-step"><span>4</span><p>御見積もりと契約</p></div>
                      <div className="flow-content">
                        <p>見積もりや工事の内容を十分確認した後、ご契約へ。<br />
                          金額以外に施工実施図面や工事工事内容、契約条項、支払い条件などについてもしっかりご説明致します。</p>
                      </div>
                    </div>
                  </div>
                  <div className="flow-block-wrap">
                    <p className="vertical">施工 〜 完成・引き渡しまで約３ヶ月程度</p>
                    <div className="flow-block">
                      <div className="flow-step"><span>5</span><p>施工</p></div>
                      <div className="flow-content">
                        <p>待ちに待っていた薪ストーブの設置工事が行われます。<br />
                          屋外の煙突の配管と雨仕舞い工事からはじまり、炉台や炉壁などの本体廻りの不燃処理に伴う左官工事(＊必要な場合)を含めた内装工事が完了した段階で室内の煙突の配管とフロアプレート、ウォールプレート(＊必要な場合)の設置と合わせて本体を設置します。<br />
                          ※お客様の工事の状況により、施工にかかる時間は大幅に前後する場合がございます。</p>
                      </div>
                    </div>
                    <div className="flow-block">
                      <div className="flow-step"><span>6</span><p>完成・引き渡し</p></div>
                      <div className="flow-content">
                        <p>慣らし焚きを行いながらの「火入れ式」で薪ストーブ本体の適切な使用方法や煙突を含めたメンテナンス方法の説明をいたします。</p>
                      </div>
                    </div>
                    <div className="flow-block">
                      <div className="flow-step"><span>7</span><p>メンテナンス</p></div>
                      <div className="flow-content">
                        <p>安全で快適なストーブライフを維持するためにオフシーズン中に行う、必要不可欠なアフターフォローです。<br />
                          各施工販売代理店では、薪ストーブ本体をはじめ、煙突の掃除と保守点検など、各種サポートを行っておりますので、担当販売店にご相談ください。</p>
                      </div>
                    </div>
                  </div>
                  <p className="md:text-right my-6">※あくまでも参考価格(目安)となります。</p>
                </div>
              </div>
            </div>
            <div id="about" className="content-block">
              <p className="text-2xl c-yellow mb-4">薪について</p>
              <p className="text-xl c-gray mb-4">ストーブに適した薪と薪の燃焼</p>
              <p>木の発熱量は樹種に左右されることは殆どなく、その質量に比例します。一般的に広葉樹は針葉樹に比べて密度が高く、同じ体積・含水率では発熱量が高い為、火持ちが良く重宝されています。また、松などの針葉樹はヤニの発生が多く、煙道内を汚してしまい、煙道火災などのトラブルの原因につながるので、ヤニの少ない樹種の方が薪に適していると言われています。</p>
              <p className="mt-6">
                アンデルセンストーブをご使用する際は、
                <span className="c-yellow text-lg py-2 block">薪の含水率を15～20％まで乾燥させていただくこと</span>
                をお願いしています。
              </p>
              <p className="mt-6">木は燃焼する過程で、内部に含まれている水分を蒸発させながら燃えます。この時、せっかく木の燃焼で得られた熱も、水分の蒸発に費やされてしまいます。充分に乾燥をしていない薪では、ストーブ本来の暖かさをフルに発揮することもできません。また、水分を多く含む薪では多くの煙を出し、ストーブ本体や煙道内にタールを付着させてしまいます。タールは煙道火災を引き起こす原因にもなります。</p>
              <div className="mt-12">
                <p className="text-xl c-gray mb-6">広葉樹と針葉樹の特徴</p>
                <table className="table-head">
                  <tr>
                    <th className="md:w-2/12">種類</th>
                    <th>長所</th>
                    <th>短所</th>
                  </tr>
                  <tr>
                    <th className="sp">種類</th>
                    <td>広葉樹</td>
                    <th className="sp">長所</th>
                    <td>
                      <ul>
                        <li className="listmark">・高比重で火持ちが良いため、使用する薪の量が針葉樹と比べて少ないので、薪の保管スペースも針葉樹のそれよりも多く取らなくて済む。</li>
                        <li className="listmark">・ほのかな香りを放つ樹種もあるので、リラックスした雰囲気を演出でき、薪ストーブを使った料理に使用すれば香りが料理に付き美味しさを引き立たせます。</li>
                      </ul>
                    </td>
                    <th className="sp">短所</th>
                    <td>
                      <ul>
                        <li className="listmark">・硬くて節の多い樹種は薪作りに苦労する。</li>
                        <li className="listmark">・手を加えないと腐ってしまう樹種もある。</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th className="sp">種類</th>
                    <td>針葉樹</td>
                    <th className="sp">長所</th>
                    <td>
                      <ul>
                        <li className="listmark">・比較的コストが低い。</li>
                        <li className="listmark">・柔らかい為、薪作りが楽にできる。</li>
                        <li className="listmark">・着火性に優れ、よく燃えるので、焚きつけ材として重宝されている。</li>
                      </ul>
                    </td>
                    <th className="sp">短所</th>
                    <td>
                      <ul>
                        <li className="listmark">・低比重で火持ちが悪いので、燃料消費量が多い、その為、薪の保管スペースも広く取る必要がある。</li>
                        <li className="listmark">・特に松はヤニが発生し易く、煙道内を汚してしまい、煙道火災の原因になる。</li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="mt-12">
                <p className="text-xl c-gray mb-4">針葉樹の有効な利用方法</p>
                <p>十分乾燥をさせた上、こまめな給気調整管理を行えば、煤の発生量も低減でき、薪としての使用が可能です。その為、用途や目的に合わせ、針葉樹と広葉樹を混ぜて使用したり、焚き付け材として使い分けると良いでしょう。<br />
                  ＊定期的な煙突掃除などのメンテナンスも忘れずに。</p>
              </div>
              <div className="md:flex mt-12">
                <div className="md:w-1/2 md:pr-8">
                  <p className="text-xl c-gray mb-4">薪の保管について</p>
                  <p>薪の乾燥を促すため、風通しが良く、建物の南側などに位置する、陽が当たる場所に最低でも1～２年積んだ状態で保管すれば、おおよそ適度な含水率になると考えられます。<br />
                    また、薪棚などを設置する場合は地面からの湿気を防ぐため高床にしたり、雨風や雪による浸水を防ぐためにカバーを掛けたり屋根を用意することをお勧めします。 シーズン中の秋～冬にかけての薪は湿気を吸収する為、使用する数日前より屋内に保管して下さい。</p>
                </div>
                <div className="md:w-1/2 md:pl-8">
                  <Image className="full-image" filename="beginner/about-01.jpg" />
                </div>
              </div>
              <div className="mt-12">
                <p className="text-xl c-gray mb-4">燃やしてはいけないもの</p>
                <p>接着剤や加圧注入剤などの薬剤が含まれ、有毒ガスを発生する合板、集成材、土台の他に、腐った薪や塩分を含んだ流木など。 建築端材を使用する際、釘が付いている場合があるので、予め抜き取ってから使用して下さい。</p>
              </div>
            </div>
            <div id="procedure" className="content-block">
              <p className="text-2xl c-yellow mb-4">薪ストーブの着火から消火まで</p>
              <p className="text-xl c-gray mb-4">着火</p>
              <div className="step">
                <div className="step-block">
                  <div className="step-number"><span>1</span></div>
                  <div className="step-content">
                    <p className="text-lg">1次、2次給気口を全開にしてドアを開け、着火剤等を中心に置きます。</p>
                    <p className="c-gray">この着火剤の量は小枝に着火する程度でよく、大量に使用する必要がありません。</p>
                  </div>
                </div>
                <div className="step-block">
                  <div className="step-number"><span>2</span></div>
                  <div className="step-content">
                    <p className="text-lg">そのうえに小枝を数本のせ、着火剤に着火します。</p>
                    <ul className="c-gray">
                      <li className="listmark">・切りたての小枝は、水分が多いため燃えません。充分乾燥したものを使用してください。</li>
                      <li className="listmark">・着火剤には当社の着火剤ファヤーアップ、またはフラムゴをおすすめ致します。</li>
                    </ul>
                  </div>
                </div>
                <div className="step-block">
                  <div className="step-number"><span>3</span></div>
                  <div className="step-content">
                    <p className="text-lg">充分なドラフトが得られない場合には、ドアを1cm程度開けておいてください。</p>
                    <div className="c-gray">
                      <p>薪は奥の方に置き、手前は少し開けておくこと</p>
                      <ul className="c-gray">
                        <li className="listmark">・手前で薪を燃やすと室内に煙が出てきたり燃えている薪が落ちたりして危険です。</li>
                        <li className="listmark">・安定して燃えはじめたら、ドアを完全に閉めてください。</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="step-block">
                  <div className="step-number"><span>4</span></div>
                  <div className="step-content">
                    <p className="text-lg">細い薪や小枝に火が回ってきたら、太めの薪をくべ始めます。</p>
                    <div className="c-gray">
                      <p>薪は奥の方に置き、手前は少し開けておくこと</p>
                      <ul className="c-gray">
                        <li className="listmark">・手前で薪を燃やすと室内に煙が出てきたり燃えている薪が落ちたりして危険です。</li>
                        <li className="listmark">・安定して燃えはじめたら、ドアを完全に閉めてください。</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-xl c-gray mb-4 mt-10">調整</p>
              <div className="step">
                <div className="step-block">
                  <div className="step-number"><span>5</span></div>
                  <div className="step-content">
                    <p className="text-lg">徐々に薪の量を増やしていきます。</p>
                    <p className="c-attention">大量に薪を入れないこと</p>
                    <p className="c-gray">大量に薪を入れた場合、ストーブの炉内が過度の高温状態となり、本体を傷める原因となるだけでなく、くべた薪が手前に倒れドアガラス等を破損することがあります。薪が倒れそうな時は火掻き棒等で位置を調整してください。<br />
                      また薪が多すぎると給気量が不足して不完全燃焼となり燃焼効率が悪くなるだけではなく、ススやタールの発生原因となります。</p>
                  </div>
                </div>
                <div className="step-block">
                  <div className="step-number"><span>6</span></div>
                  <div className="step-content">
                    <p className="text-lg">一次給気口を完全に閉めて、2次給気口で給気量を調節します。</p>
                    <p className="c-attention">空気調整するときは必ず耐熱手袋等を着用すること！</p>
                    <ul className="c-gray">
                      <li className="listmark">・燃焼の様子を見ながら１次空気の調整を行ってください。</li>
                      <li className="listmark">・ストーブの表面温度が高温になったら、1次給気口を完全に閉めて、2次給気口で調節してください。2次給気口を徐々に絞ると安定したオキ状態で燃焼します。</li>
                    </ul>
                  </div>
                </div>
              </div>
              <p className="text-xl c-gray mb-4 mt-10">薪の追加</p>
              <div className="step">
                <div className="step-block">
                  <div className="step-number"><span>7</span></div>
                  <div className="step-content">
                    <p className="text-lg">燃焼中にドア開ける時は、煙突のダンパーを全開にして後、ドアを少し開けてしばらくしてから全開にしてください。 （注：煙突にダンパーが付いている場合）</p>
                    <p className="c-attention">薪追加時は必ず耐熱手袋等を着用すること！</p>
                    <p className="c-gray">ダンパーを閉めたままドアを開けると、煙や炎が出てくることがあり大変危険です。<br />
                      勢いよくドアを開けないこと！ ドアを勢いよく開けると煙や炎が出てくることがあり大変危険です。ほんの少し開けて4～5秒待った後、ゆっくり開けてください。</p>
                  </div>
                </div>
                <div className="step-block">
                  <div className="step-number"><span>8</span></div>
                  <div className="step-content">
                    <p className="text-lg">通常の燃焼は、2～3本の薪が最適です。</p>
                    <p className="c-attention">大量に薪を入れないこと！</p>
                    <p className="c-gray">大量に薪を入れた場合、ストーブの炉内が過度の高温状態となり、本体を傷める原因となるだけでなく、くべた薪が手前に倒れドアガラス等を破損することがあります。薪が倒れそうな時は火掻き棒等で一を調整してください。また薪が多すぎると給気量が不足して不完全燃焼となり燃焼効率が悪くなるだけではなく、ススやタールの発生原因となります。</p>
                  </div>
                </div>
              </div>
              <p className="text-xl c-gray mb-4 mt-10">消火</p>
              <div className="step">
                <div className="step-block">
                  <div className="step-number"><span>9</span></div>
                  <div className="step-content">
                    <p className="text-lg">基本的には自然に燃え尽きるのを待ってください。</p>
                    <p className="c-gray mb-6">ドア、1次/2次給気口を閉じてもすぐには火を消すことはできません。<br />
                      morso、LEDA製品は不完全燃焼を防ぐために全ての給気口を閉じてもある程度の空気が入るように設計されております。<br />
                      緊急時以外は薪の投入量を調整してある程度の空気量を確保して自然に燃え尽きるのを待ってください。</p>
                    <p className="c-attention">水をかけて消すことは絶対にしないこと！</p>
                    <p className="c-gray">熱を帯びたストーブに水をかけると鋳物が割れるなど大変危険です。災害時などすぐに消火する必要がある場合には必ず消火器で消火してください。そのため消火器の位置はあらかじめ確認しておくようにしてください。</p>
                  </div>
                </div>
                <div className="step-block">
                  <div className="step-number"><span>10</span></div>
                  <div className="step-content">
                    <p className="text-lg">2〜3cm残して余分な灰をかき出してください。</p>
                    <p className="c-attention">灰はダンボール箱等の可燃物には絶対に入れない事！</p>
                    <p className="c-gray">取り出した灰の中には細かいオキがあったり、灰自身が熱を帯びている場合がありますので、一度不燃性の容器に移し、完全に冷えていることを確認してから処理をしてください。</p>
                    <ul className="c-gray mt-6">
                      <li className="listmark">・灰受け皿があれば、トレイごと取り出すか、トレイがなければ、灰取り用シャベルで灰が落ちないように注意してかき出してください。</li>
                      <li className="listmark">・炉内に灰が2～3cm残っていると、つぎの着火時に火がつきやすくなり、火持ちもよくなるばかりでなく、燃焼室内の保護にもなります。</li>
                      <li className="listmark">・灰受け皿トレイにも2～3cm灰を残してください。</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div id="maintenance" className="content-block">
              <p className="text-2xl c-yellow mb-4">メンテナンス</p>
              <div className="md:flex">
                <div className="md:w-8/12 pr-8">
                  <p className="text-xl c-gray mb-4">本体の掃除</p>
                  <p>薪ストーブを使用していると、ガラスが煤(スス)で汚れてしまいます。<br />
                    特に針葉樹の薪を使用した場合は広葉樹の薪に比べてヤニが多く、べっとりとした煤が付いてしまうので専用のクリーナーで拭いてあげましょう。<br />
                    ストーブ外側を見渡して塗装が剥がれている部分があればサビの原因になりますので耐熱塗料やストーブポリッシュなどで再塗装してください。</p>
                </div>
                <div className="md:w-4/12 md:pl-8">
                  <Image className="full-image" filename="beginner/maintenance-01.jpg" />
                </div>
              </div>
              <div className="md:flex mt-10">
                <div className="md:w-8/12 pr-8">
                  <p className="text-xl c-gray mb-4">灰の掃除</p>
                  <p>薪ストーブを使い始めの頃は灰は沢山でますが、薪ストーブを上手に使える様になると薪から出る灰の量は減ってきます。<br />
                    薪ストーブの炉床に2〜3cm位は灰を残して使用するようにしましょう。灰が敷き詰められている状態にすることで蓄熱力もアップし、薪の火持ちも良くなります。</p>
                  <p className="mt-6">薪ストーブを使用しなくなったシーズン終わりには薪ストーブ本体から灰をすべて除去してください。<br />
                    灰は湿気を吸収しますので梅雨前には掃除を終わらせてください。</p>
                </div>
                <div className="md:w-4/12 md:pl-8">
                  <Image className="full-image" filename="beginner/maintenance-02.jpg" />
                  <p className="text-xs mt-4">火のついた炭が残っている可能性もあるので、灰は必ず不燃性の容器に取り出してください。<br />
                    また、その容器は不燃物の上で保管してください。</p>
                </div>
              </div>
              <div className="mt-12">
                <p className="text-xl c-gray mb-4">煙突の掃除</p>
                <p>煙突内部にこびりついた「タールやすす」をそのままにしておくと煙道火災を引き起こす可能性があります。年に1回以上は、煙突掃除をするようにお願いをしています。<br />
                  屋根に登らなくてはならない環境など危険が伴う場合は専門業者に依頼してください。</p>
              </div>
              <div className="mt-12">
                <p className="text-xl c-gray mb-4">消耗品の交換</p>
                <p>ドアやガラスのパッキンは使用している内に弾力がなくなり余計な空気が入るなどします。<br />
                  2年程度を目安に交換が必要になりますがそれ以前でも空気調整の感覚が変わってきたら交換してください。</p>
                <p className="mt-6">また炉内の耐火レンガなども熱による劣化や薪との接触などで消耗してきます。ヒビが入っていても奥の面が見えておらず取り付けも安定しているようでしたらすぐに交換する必要はありませんがレンガが崩れて奥の面が見えるような状態でしたら交換してください。</p>
                <p className="mt-6">その他変形が激しい部品や破損している部品が無いか確認してください。<br />
                  部品交換や正面から見えない部分の掃除をおこなうための部品の取り外しが困難な場合は専門業者へご依頼ください。</p>
              </div>
            </div>
            <Link to="/accessory/detail/?id=maintenance" className="btn full white mt-12">メンテナンス用品はこちら</Link>
          </div>
          <div className="side-list">
        <ul className="sticky">
              <li>
                <GoLink to="howto" spy smooth duration={800}>
                  薪ストーブの設置と使用方法
                </GoLink>
              </li>
              <li>
                <GoLink to="type" spy smooth duration={800}>
                  薪ストーブの種類
                </GoLink>
              </li>
              <li>
                <GoLink to="flow" spy smooth duration={800}>
                  価格と設置までの流れ
                </GoLink>
              </li>
              <li>
                <GoLink to="about" spy smooth duration={800}>
                  薪について
                </GoLink>
              </li>
              <li>
                <GoLink to="procedure" spy smooth duration={800}>
                  薪ストーブの着火から消火まで
                </GoLink>
              </li>
              <li>
                <GoLink to="maintenance" spy smooth duration={800}>
                  メンテナンス
                </GoLink>
              </li>
            </ul>
          </div>
        </section>
        <section className="bread">
          <ul>
            <li>
              <Link to="/">
                <Image filename="ico/home.png" />
              </Link>
            </li>
            <li>初めての方へ</li>
          </ul>
        </section>
      </Layout>
  );

export default BeginnerPage;
